import { NavItem } from '../nav-item.model';
import { ProgramApplicationNav } from './items/program-application.constant';
import { ProgramApplicationsInvestorNav } from './items/program-applications-investor.constant';
import { ProgramAssetsNav } from './items/program-assets.constant';
import { ProgramCIPortfolioSubmissionsNav } from './items/program-ci-portfolio-submissions.constant';
import { ProgramClaimsNav } from './items/program-claims-new.constant';
import { ProgramConfigurationNav } from './items/program-configuration.constant';
import { ProgramDeveloperCIPortfolioSubmissionsNav } from './items/program-developer-ci-portfolio-submissions.constant';
import { ProgramHomeNav } from './items/program-home.constant';
import { ProgramMessagesNav } from './items/program-messages.constant';
import { ProgramReportingNav } from './items/program-reporting-new.constant';
import { ProgramResourcesNav } from './items/program-resources.constant';

export class ProgramNav {
  public static item: NavItem[] = [
    ProgramHomeNav.item,
    ProgramResourcesNav.item,
    ProgramApplicationsInvestorNav.item,
    ProgramApplicationNav.item,
    ProgramAssetsNav.item,
    ProgramDeveloperCIPortfolioSubmissionsNav.item,
    ProgramClaimsNav.item,
    ProgramReportingNav.item,
    ProgramMessagesNav.item,
    ProgramConfigurationNav.item
  ];
}
