<div>
  <h3 class="privacy-policy-heading">Privacy Policy</h3>
  <p>Odyssey Energy Solutions, Inc. (<strong>“Odyssey”</strong>) is committed to protecting your privacy.  We have prepared this Privacy Policy to describe to you our practices regarding the personal data we collect about users of our customers, such as business contact information. Your organization’s “Customer Data”, which we collect and process through our site (<strong>“Site”</strong>) on behalf of your organization as part of our service (<strong>“Service”</strong>), is treated in accordance with our <a href="https://platform.odysseyenergysolutions.com/#/terms">subscription terms of service</a> and not as described in this Privacy Policy.</p>
  <p><strong>Please read the Privacy Policy carefully to understand our practices regarding your personal data and how we will treat it.  <em>Please pay particular attention to the sections entitled “International Transfer” and “Your Rights.”</em></strong></p>
  <ol class="undo-reset-ol">
    <li><strong>Who we are. </strong>For the purpose of applicable data protection legislation, the data controller of your personal data is Odyssey Energy Solutions at 1519 Pine St., Unit 1, Boulder, CO 80302, USA. Our data protection officer may be contacted by emailing <a href="mailto:support@odysseyenergysolutions.com">support&#64;odysseyenergysolutions.com</a>.</li>
    <li><strong>What is personal data? </strong>We collect information about you in a range of forms, including personal data.  As used in this Policy, “personal data” is defined in the European General Data Protection Regulation, and includes any information which, either alone or in combination with other information we hold about you, identifies you, including, for example, your name, postal address, email address and telephone number. </li>
    <li><strong>Why do we need your personal data? </strong>We need certain personal data in order to communicate with you and to deliver the Service to you. </li>
    <li><strong>Your consent. </strong>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data only where we need to perform the contract we are about to enter into or have entered into with you or where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests. Additionally, by registering with Odyssey or submitting personal data through our Service, you expressly consent to the collection, use and disclosure of your personal data in accordance with this Privacy Policy.  When you register with us, you are required to consent to our Privacy Policy.  This consent provides us with an additional legal basis under applicable law to process your personal data. You maintain the right to withdraw such consent at any time. </li>
    <li><strong>Types of information we collect. </strong>We collect information from you in a variety of ways: when you interact with our Service, when you register, and when you use the Service.
      <ol>
        <li><strong>Registration. </strong>We may collect personal data from you, such as your first and last name, e-mail address, phone number, and password when you create an account or otherwise register as a user of the Service.</li>
        <li><strong>Payment. </strong>If you pay for the Service through the Site, we will collect all information necessary to complete the transaction, including your name, credit card information, and billing information. We use the payment processor Stripe to process all payments we collect through the Service. Odyssey does not store your credit card details.</li>
        <li><strong>Additional voluntary disclosures by you. </strong>If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply. We may also collect personal data at other points in the Service that state that personal data is being collected.</li>
        <li><strong>Automatic data collection.</strong>
          <ol>
            <li><u>IP Address</u>. To make our Service more useful to you, our servers (which may be hosted by a third-party service provider) collect information from you, including your browser type, operating system, Internet Protocol (<strong>“IP”</strong>) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time stamp for your visit.  We may associate your IP address with your account and your other personal data.</li>
            <li><u>Log Files</u>. As is true of most websites, we gather certain information automatically and store it in log files.  This information includes IP addresses, browser type, Internet service provider (<strong>“ISP”</strong>), referring/exit pages, operating system, date/time stamp, and clickstream data.  We use this information to analyze trends, administer the Service, track users’ movements around the Site or the App, gather demographic information about our user base as a whole, and better tailor our Service to our users’ needs.  For example, some of the information may be collected so that when you visit the Site or use the App again, it will recognize you and the information could then be used to serve advertisements and other information appropriate to your interests.  Except as noted in this Privacy Policy, we do not link this automatically-collected data to personal data.</li>
            <li><u>Cookies</u>. Like many online Service, we use cookies to collect information.  “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing the website.  We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience of our Service. <br /><br />We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which are used only by us to recognize your computer or mobile device when it revisits our Service; and (2) third party cookies, which are served by service providers on our Service, and can be used by such service providers to recognize your computer or mobile device when it visits other websites.</li>
            <p id="privacy-policy-table-heading"><strong>Our Service uses the following types of cookies for the purposes set out below:</strong></p>
            <table class="tos-table">
              <tr>
                <th><strong>Type of Cookie</strong></th>
                <th><strong>Purpose</strong></th>
              </tr>
              <tr>
                <td><em>Essential Cookies</em></td>
                <td>These cookies are essential to provide you with our Service and to enable you to use some of the features. For example, they help the content of the pages you request load quickly. Without these cookies, the Service that you have asked for cannot be provided, and we only use these cookies to provide you with those Service.</td>
              </tr>
              <tr>
                <td><em>Functionality Cookies</em></td>
                <td>These cookies allow our Service to remember choices you make when you use our Service, such as remembering your language preferences, remembering your login details and remembering the changes you make to other parts of our Service which you can customize. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you visit our Service.</td>
              </tr>
              <tr>
                <td><em>Analytics and Performance Cookies</em></td>
                <td>These cookies are used to collect information about traffic to our Service and how users use our Service. The information gathered does not identify any individual visitor. The information is aggregated and anonymous. It includes the number of visitors to our Service, the websites that referred them to our Service, the pages they visited on our Service, what time of day they visited our Service, whether they have visited our Service before, and other similar information.  We use this information to help operate our Service more efficiently, to gather broad demographic information and to monitor the level of activity on our Service.<br /><br />We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is only used to improve how our Service works. You can find out more information about Google Analytics cookies here: <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">https://developers.google.com/analytics/resources/concepts/gaConceptsCookies</a>. You can find out more about how Google protects your data here: <a href="www.google.com/analytics/learn/privacy.html">www.google.com/analytics/learn/privacy.html</a>.<br /><br />You can prevent the use of Google Analytics relating to your use of Service by downloading and installing the browser plugin available via this link: <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">http://tools.google.com/dlpage/gaoptout?hl=en-GB</a></td>
              </tr>
              <tr>
                <td><em>Targeted and Advertising Cookies</em></td>
                <td>These cookies track your browsing habits to enable us to show advertising which is more likely to be of interest to you. These cookies use information about your browsing history to group you with other users who have similar interests. Based on that information, and with our permission, third party advertisers can place cookies to enable them to show ads which may be relevant to your interests while you are on third party websites.</td>
              </tr>
              <tr>
                <td><em>Social Media Cookies</em></td>
                <td>These cookies are used when you share information using a social media sharing button or “like” button on Service or you link your account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+. The social network will record that you have done this.</td>
              </tr>
            </table>
            You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” facility).  Many browsers are set to accept cookies until you change your settings.  Further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> and <a href="www.youronlinechoices.com.uk">www.youronlinechoices.com.uk</a>.<br /><br />
            If you do not accept our cookies, you may experience some inconvenience in your use of our Service. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our Service. 
            <li><u>Analytics</u>. We may use third-party analytics providers (like Google Analytics and Mixpanel) to help analyze how users use the Service.  These analytics providers use cookies and other technologies to collect information such as how often users use the Service, what pages they visit, and what other sites they used prior to coming to the Site. We use the information we get from these providers only to improve the Service. They collect the IP address assigned to you on the date you visit the Site or use the App, rather than your name or other personally identifying information.  We do not combine the information generated through the use of such analytics with your personal data. Although such Service may set persistent cookies on computer to identify you as a unique user the next time you visit the Site, the cookie is designed to be used only by the service provider.</li>
          </ol>
        </li>
      </ol>
    </li>
    <li><strong>Use of your personal data.</strong>
      <ol>
        <li><strong>General Use</strong>. In general, personal data we collect is used to provide the Service to you, enable your use of the Service, respond to requests that you make, or to aid us in serving you better.  We use your personal data in the following ways:
          <ul class="undo-reset-ul">
            <li>•	Facilitate the creation of and to secure your account</li>
            <li>•	With your authorization, to connect you to other third-parties through the Service</li>
            <li>•	Identify you as a user in our system</li>
            <li>•	Provide improved administration of our Service</li>
            <li>•	Provide the Service you request</li>
            <li>•	Improve the quality of experience when you interact with our Service</li>
            <li>•	Send you a welcome e-mail to verify ownership of the e-mail address provided when your account was created</li>
            <li>•	Send you administrative e-mail notifications, such as security or support and maintenance advisories</li>
            <li>•	Respond to your inquiries related to employment opportunities at Odyssey or other requests</li>
            <li>•	Make telephone calls to you, from time to time, as a part of account creation, fraud protection, or to solicit your feedback</li>
            <li>•	Send newsletters, surveys, offers, and other promotional materials related to our Service and for other marketing purposes of Odyssey</li>
            <li>•	Send mobile application notifications to you</li>
            <li>•	Display promotions to you, including promotions relevant to your location.</li>
          </ul>
        </li>
        <li><strong>User Testimonials</strong>. If you send us a testimonial about our Service, we may use and disclose it (including your first name) in our discretion.  However, we will obtain your consent prior to posting your full name along with the testimonial.</li>
        <li><strong>Anonymizing</strong>. We may anonymize personal data by excluding information (such as your name or other identifier) that makes the data personally identifiable to you.  We use this anonymized data to analyze usage and to enhance our Service and improve Site navigation.</li>
      </ol>
    </li>
    <li><strong>Disclosure of Your Personal Data</strong>. We disclose your personal data as described below and as described elsewhere in this Privacy Policy.
      <ol>
        <li><strong>Third-party Service Providers</strong>. We may share your personal data with third-party service providers, for example: to our hosting provider, to otherwise provide you with the Service; to conduct quality assurance testing; to facilitate creation of accounts; and to provide technical support.</li>
        <li><strong>Affiliates</strong>. We may share some or all of your personal data with our subsidiaries, joint ventures, or other companies under a common control (<strong>>“Affiliates”</strong>), in which case we will require our Affiliates to honor this Privacy Policy.  </li>
        <li><strong>Corporate Restructuring</strong>. We may share some or all of your personal data in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets.  In the event of an insolvency, bankruptcy, or receivership, personal data may also be transferred as a business asset. If another company acquires our shares, business, or assets, that company will possess the personal data collected by us and will assume the rights and obligations regarding your personal data as described in this Privacy Policy.</li>
        <li><strong>Other Disclosures</strong>. Regardless of any choices you make regarding your personal data (as described below), Odyssey may disclose personal data (a) in connection with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas or warrants served on Odyssey; (c) to protect or defend the rights or property of Odyssey or users of the Service; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Service Agreement.</li>
      </ol>
    </li>
    <li><strong>International Transfer</strong>. This Service are hosted in the United States of America and subject to applicable U.S. Federal and state laws, rules and regulations. Your personal data, may be transferred to, stored at and processed by us and our affiliates and other third parties outside the country in which you reside, including, but not limited to the United States, where data protection and privacy regulations may not offer the same level of protection as in your country.  By using our Service, you consent and agree to this transfer, storing, processing and use of your personal data in accordance with this Privacy Policy.</li>
    <li><strong>Retention</strong>. We will only retain your personal data for one year after our relationship with you or your organization ends, unless a longer retention period is required or permitted by law (for example for regulatory purposes or auditing purposes).</li>
    <li><strong>Sensitive Personal Data</strong>. Subject to the following paragraph, we ask that you not send us, and you not disclose, any sensitive personal data (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on or through the Service or otherwise to us.</li>
    <li><strong>Your Rights</strong>. If you wish to exercise any of the following rights, please contact us using the details below. In your request, please make clear: (i) <strong>what</strong> personal data is concerned; and (ii) <strong>which of the rights</strong> you would like to enforce.  For your protection, we may only implement requests with respect to the personal data associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request.  We will try to comply with your request as soon as reasonably practicable and in any event, within one month of your request. Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting such change or deletion.
      <ol>
        <li><strong>Opt-out</strong>. You may contact us any time by email to opt-out of: (i) direct marketing communications; (ii) automated decision-making and/or profiling, except to the extent permitted under applicable law; or (iii) any new processing of your personal data that we may carry out beyond the original purpose. Please note that your use of some of the Service may be ineffective upon opt-out.  Despite your indicated e-mail preferences, we may send you service-related communications, including notices of any updates to our Terms of Service or Privacy Policy.</li>  
        <li><strong>Access</strong>. You may access the information we hold about you at any time via your account, where applicable, or by contacting us directly.</li>
        <li><strong>Amend</strong>. You can also contact us to update or correct any inaccuracies in your personal data.</li>
        <li><strong>Move</strong>. Your personal data is portable – i.e. you to have the flexibility to move your data to other service providers as you wish.</li>
        <li><strong>Erase and forget</strong>. In certain situations, for example when the information we hold about you is no longer relevant or is incorrect, you can request that we erase your data.</li>
      </ol>
    </li>
    <li><strong>Complaints</strong>. We are committed to resolve any complaints about our collection or use of your personal data. If you would like to make a complaint regarding this Privacy Policy or our practices in relation to your personal data, please contact us at: support&#64;odysseyenergysolutions.com. We will reply to your complaint as soon as we can and in any event, within 45 days. We hope to resolve any complaint brought to our attention, however if you feel that your complaint has not been adequately resolved, you reserve the right to contact your local data protection supervisory authority.</li>
    <li><strong>Security</strong>. Odyssey is committed to protecting the security of your personal data.  We use a variety of industry-standard security technologies and procedures to help protect your personal data from unauthorized access, use, or disclosure.  We also require you to enter a password to access your account information. Please do not disclose your account password to unauthorized people.  No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while Odyssey uses reasonable efforts to protect your personal data, Odyssey cannot guarantee its security.</li>
    <li><strong>Changes to This Privacy Policy</strong>. This Privacy Policy is subject to occasional revision, and if we make any material changes in the way we use your personal data, we will notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our Site.  Any changes to this Privacy Policy will be effective upon the earlier of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site.  These changes will be effective immediately for new users of our Service.  Please note that at all times you are responsible for updating your personal data to provide us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.  If you do not wish to permit changes in our use of your personal data, you must notify us prior to the effective date of the changes that you wish to deactivate your account with us.  Continued use of our Service, following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</li>
    <li><strong>Questions; Contacting Odyssey; Reporting Violations</strong>. If you have any questions or concerns about our Privacy Policy or our data collection or processing practices, or if you want to report any security violations to us, please contact us at the following address or email:<br /><br />
      Odyssey Energy Solutions<br />
      1519 Pine St., Unit 1, Boulder, CO 80302, USA<br />
      <a href="mailto:support@odysseyenergysolutions.com">support&#64;odysseyenergysolutions.com</a>
    </li>
  </ol>
  <hr />
  <p>
    Last Modified: June 12, 2018
  </p>
</div>
