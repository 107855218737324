import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProgramApplicationStageConfiguration } from '@program/shared/program-application-stage-configuration.model';
import { ProgramApplicationStatus } from '@program/shared/program-application-status.model';
import { ProgramApplication } from '@program/shared/program-application.model';
import { ProgramModulePermissions } from '@program/shared/program-module-permissions.model';
import { Program } from '@program/shared/program.model';
import { ProgramService } from '@program/shared/program.service';
import { EventService } from '@shared/event.service';
import { UtilityService } from '@shared/services/utility.service';
import { ROLE_TYPE } from '@user/role-type';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { kebabCase } from 'lodash-es';
import { take } from 'rxjs/operators';
import { SideNavigationSecondaryService } from 'src/app/side-navigation-secondary/side-navigation-secondary.service';

import { NavItem } from './navi-item/nav-item.model';
import { ProgramNav } from './navi-item/program-detail/program-nav.constant';

@Injectable({
  providedIn: 'root'
})
export class ProgramMenuService {
  private user: User;
  private program: Program;

  constructor(private _programService: ProgramService,
              private _sideNavigationSecondaryService: SideNavigationSecondaryService,
              private _router: Router,
              private _utilityService: UtilityService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _eventService: EventService) {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  private programLoadError() {
    this._eventService.error(this._translateService.instant('error-message.program-info-not-found'));
    this._router.navigateByUrl('oes/finance/programs');
  }

  private buildNav(navItems: NavItem[], modulePermissions: ProgramModulePermissions): NavItem[] {
    const keys = Object.keys(modulePermissions);
    keys.forEach(modulePermission => {
      if (modulePermission === 'application') {
        if (!modulePermissions.application?.length || (this.program?.sponsor?.id === this.user?.organization?.id) || modulePermissions?.application?.every(app => app?.application?.view === false)) {
          const removeIndex = navItems.findIndex(navItem => navItem.url === kebabCase(modulePermission));
          if (removeIndex >= 0) {
            navItems.splice(removeIndex, 1);
          }
        }
      } else {
        if (!modulePermissions[modulePermission].view) {
          const removeIndex = navItems.findIndex(navItem => navItem.url === kebabCase(modulePermission));
          if (removeIndex >= 0) {
            navItems.splice(removeIndex, 1);
          }
        }
      }
    });

    return navItems;
  }

  public selectMenuByProgram(selectedRole: ROLE_TYPE, baseUrl: string) {
    const uuid = this.uuid(baseUrl);
    this._programService.getProgram(uuid[0])
    .pipe(take(1))
    .subscribe((program: Program) => {
      this.program = program;
      if (program) {
        this._sideNavigationSecondaryService.program = program;
        const modulePermissions: ProgramModulePermissions = program.programPermissions.modulePermissions;
        const nav: NavItem[] = this.buildNav(Object.assign([], ProgramNav.item), modulePermissions);

        if (nav.find(item => item.url === 'application') &&
            program?.programApplicationStageConfigurations?.length &&
            modulePermissions?.application?.length && (program?.sponsor?.id !== this.user.organization.id)) {
          const appIndex = nav.findIndex(item => item.title === 'program.tab.application');
          const applicationStages = [];

          program.programApplicationStageConfigurations.forEach((config: ProgramApplicationStageConfiguration, index: number) => {
            const matchedApplication: ProgramApplication = program.programApplications?.find((app: ProgramApplication) => app.programApplicationStageConfiguration?.id === config.id);
            const currentStatus: string = this._utilityService.findProgramApplicationStatus(matchedApplication?.applicationStatuses);
            const applicationPermissions = modulePermissions.application.find(app => app.stageRank === config.stageRank).application;
            const displayStatus = currentStatus ? `(${currentStatus})` : applicationPermissions.view ? `(${this._translateService.instant('program.configuration.not-submitted')})` : '';
            const navItem: NavItem = {
              title: config.name + ' ' + displayStatus,
              url: 'application',
              queryParams: { stage: config.stageRank },
              class: applicationPermissions.view ? 'stage-ready' : 'stage-na'
            };

            applicationStages.push(navItem);
          });
          nav[appIndex].children = applicationStages;
        }

        this._sideNavigationSecondaryService.joinParentPath(baseUrl, nav, true);
      } else {
        this.programLoadError();
      }
    },
    error => {
      this.programLoadError();
    });
  }

  private uuid(baseUrl: string): any {
    return baseUrl.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/);
  }
}
