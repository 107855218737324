import { Component } from '@angular/core';

@Component({
  selector: 'oes-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent {

  constructor() { }
}
