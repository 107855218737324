import { Component } from '@angular/core';

@Component({
  selector: 'oes-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {

  constructor() { }
}
