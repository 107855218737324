<div class="terms-content">
  <!-- Terms of Service -->
  <ng-container *ngIf="requestEulaType === eulaType.terms || requestEulaType === eulaType.termAndPrivacy">
    <oes-terms-of-service></oes-terms-of-service>
  </ng-container>

  <!-- privacy policy -->
  <ng-container *ngIf="requestEulaType === eulaType.privacyPolicy || requestEulaType === eulaType.termAndPrivacy">
    <oes-privacy-policy></oes-privacy-policy>
  </ng-container>
  

  <!-- Conflict of Interest Policy -->
  <ng-container *ngIf="requestEulaType === eulaType.conflictInterest">
    <oes-conflict-of-interest-policies></oes-conflict-of-interest-policies>
  </ng-container>
</div>
