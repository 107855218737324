<div>
  <p dir="ltr">
      These Terms of Service provide the terms and conditions pursuant to which
      Odyssey Energy Solutions (<b>“Odyssey”</b>) makes its platform services available
      to customers. The Service enables customers to develop microgrid-related
      project plans, pricing models, and quotes, to share such information for
      diligence and funding of such projects, and to find technology/services for
      such projects.
  </p>
  <br />
  <ol>
    <li dir="ltr">
      <b>ACCEPTANCE OF TERMS</b>. By using this Web site or any associated
      applications (the <b>“Site”</b>) in any way, including using any of the
      services or functionality (the <b>“Service”</b>) made available or enabled
      via the Site by Odyssey, the organization for which the Services
      are used (<b>“Customer”</b>) agrees to these Terms of Service. <b>The person
      registering with Odyssey represents that he or she has the
      authority to bind Customer to these Terms</b>. Odyssey makes the
      Service available only if Customer has agreed to these Terms of
      Service and created or received a username and password or other
      log-in ID and password. Certain parts of the Service may also be
      subject to posted guidelines, rules or terms (<b>“Additional Terms”</b>).
      If there is any conflict between these Terms and the Additional
      Terms, the Additional Terms take precedence in relation to the
      applicable parts of the Service. These Terms, and any applicable
      Additional Terms, are referred to herein as the <b>“Terms.”</b> Odyssey
      may change the Terms from time to time at its sole discretion, and
      if Odyssey makes any material changes, Odyssey will notify Customer
      by sending an email to the last email address Customer provided
      and/or by posting notice of the change on the Site. Any material
      changes to these Terms will be effective upon the earlier of thirty
      (30) calendar days following dispatch of an email notice to
      Customer or thirty (30) calendar days following posting of notice
      of the changes on the Site. These changes will be effective
      immediately for new users of the Site or Service. Odyssey may
      require Customer to provide consent to the updated Terms in a
      specified manner before further use of the Service is permitted.
      Otherwise, Customer’s continued use of the Site or the Service
      after the effective date of the new Terms constitutes Customer’s
      acceptance of the changes.
    </li>
    <li dir="ltr">
      <b>DEFINITIONS</b>. Capitalized terms shall have the meanings set forth in
      this section or in the section where they are first used.
      <ol>
        <p dir="ltr">
            <b>“Content”</b> means any and all information, data, project plans, quotes,
            diligence reviews, results, ideas, plans, sketches, texts, files, links,
            images, photos, video, sound, inventions (whether or not patentable),
            notes, works of authorship, articles, feedback, or other materials.
        </p>
        <p dir="ltr">
            <b>“Customer Data”</b> means any Content provided, imported or uploaded to,
            generated, or otherwise used by Customer or on Customer’s behalf with the
            Service. However, Customer Data does not include personal data about a
            User, such as business contact information, registration information,
            payment method information, cookies, and nonconfidential information about
            how the User uses the Service, which is subject to the Odyssey Privacy
            Policy, and not these Terms.
        </p>
        <p dir="ltr">
            <b>“Documentation”</b> means all specifications, user manuals, and other technical
            materials relating to the Services.
        </p>
        <p dir="ltr">
            <b>“Intellectual Property Rights”</b> means any and all now known or hereafter
            existing (a) rights associated with works of authorship, including
            copyrights, mask work rights, and moral rights; (b) trademark or service
            mark rights; (c) trade secret rights; (d) patents, patent rights, and
            industrial property rights; (e) layout design rights, design rights, and
            other proprietary rights of every kind and nature other than trademarks,
            service marks, trade dress, and similar rights; and (f) all registrations,
            applications, renewals, extensions, or reissues of the foregoing, in each
            case in any jurisdiction throughout the world.
        </p>
        <p dir="ltr">
            <b>“License Administrator(s)”</b> means those Users designated by Customer who are
            authorized to purchase licenses via an Order Form and to create User
            accounts and otherwise administer Customer’s use of the Service.
        </p>
        <p dir="ltr">
            <b>“License Term”</b> means the period during which a specified number of Users
            are licensed to use the Service pursuant to the Order Form(s).
        </p>
        <p dir="ltr">
            <b>“Order Form”</b> means the form (which may be hardcopy or online on the Site)
            for the initial subscription for the Service and any subsequent order forms
            submitted and accepted online or in written form and specifying, among
            other things, the number of Users, the applicable fees, the billing period,
            and other charges as agreed to between the parties, each of which becomes
            incorporated into and a part of these Terms.
        </p>
        <p dir="ltr">
            <b>“Users”</b> means Customer’s employees or contractors who are authorized to
            utilize the Service and who are provided with access to the Service via
            username and password.
        </p>
      </ol>
    </li>
    <li dir="ltr">
      <b>LICENSE GRANT AND RESTRICTIONS.</b>
      <ol>
        <li dir="ltr">
          <b>License Grant</b>. Subject to the terms and conditions of these
          Terms, Odyssey grants Customer a non-exclusive,
          non-transferable license to use the Service as specified in
          Customer’s Order Form solely for Customer’s internal business
          purposes during the Term.
        </li>
        <li dir="ltr">
          <b>Limitations</b>. Customer agrees that Customer will not: (a) permit
          any party to access and/or use the Service, other than the
          Users authorized under these Terms; (b) rent, lease, loan, or
          sell access to the Service to any third party; (c) interfere
          with, disrupt, alter, translate, or modify the Service or any
          part thereof (d) reverse engineer or access the Service in
          order to (i) build a competitive product or service, (ii) build
          a product using similar ideas, features, functions or graphics
          of the Service, or (iii) copy any ideas, features, functions or
          graphics of the Service; (e) without Odyssey’s express written
          permission, introduce software or automated agents or scripts
          to the Service so as to produce multiple accounts, generate
          automated searches, requests and queries, or to strip or mine
          data from the Service; (f) perform or publish any performance
          or benchmark tests or analyses relating to the Service or the
          use thereof; (g) cover or obscure any page or part of the
          Service via HTML/CSS, scripting, or any other means, if any; or
          (h) use any manual or automated software, devices or other
          processes (including but not limited to spiders, robots,
          scrapers, crawlers, avatars, data mining tools or the like) to
          “scrape” or download data from any pages contained in the Site.
        </li>
        <li dir="ltr">
          <b>Accounts, Usernames and Passwords</b>. Customer will provide and
          timely update its account and profile information to maintain
          it as accurate and complete. Odyssey may provide each User (or
          allow each User to specify) a unique username and password to
          enable such User to access the Service pursuant to these Terms.
          Each username and password may only be used to access the
          Service during one (1) concurrent login session. If Odyssey
          requires it, Customer will ensure that each username and
          password issued to a User will be used only by that User.
          Customer is responsible for maintaining the confidentiality of
          all Users’ usernames and passwords, and is solely responsible
          for all activities that occur under these usernames. Customer
          agrees (a) not to allow a third party to use Customer’s
          account, usernames or passwords at any time; and (b) to notify
          Odyssey promptly of any actual or suspected unauthorized use of
          Customer’s account, usernames or passwords, or any other breach
          or suspected breach of these Terms. Odyssey reserves the right
          to terminate any username and password, which Odyssey
          reasonably determines may have been used by an unauthorized
          third party. User licenses cannot be shared or used by more
          than one individual User, but may be reassigned from time to
          time to a new User who is replacing a former User who has
          terminated employment or otherwise changed job status or
          function and no longer uses the Service.
        </li>
        <li dir="ltr">
          <b>IP Ownership</b>. The Service and all Intellectual Property Rights
          in the Service, and any of the Odyssey proprietary technology,
          including software, hardware, products, processes, algorithms,
          user interfaces, know-how, technologies, designs and other
          tangible or intangible technical material or information made
          available to Customer by Odyssey in providing the Service, but
          excluding the Customer Data (the “Odyssey Technology”), is the
          exclusive property of Odyssey or its suppliers or service
          providers. Customer hereby assigns to Odyssey all Intellectual
          Property Rights in any suggestions, ideas, enhancement
          requests, feedback, recommendations or other information
          provided by Customer related to the Service or the Odyssey
          Technology. Except as expressly set forth herein, no express or
          implied license or right of any kind is granted to Customer
          regarding the Service, the Odyssey Technology, or any part of
          the foregoing, including any right to obtain possession of any
          source code, data or other technical material relating to the
          Odyssey Technology. All rights not expressly granted to
          Customer are reserved to Odyssey. As between the parties,
          Customer owns all Intellectual Property Rights in the Customer
          Data.
        </li>
      </ol>
    </li>
    <li dir="ltr">
      <b>PAYMENT</b>.
      <ol>
        <li dir="ltr">
          <b>Charges</b>. Customer shall pay all fees or charges to Customer’s
          account in accordance with the fees, charges, and billing terms
          reflected in its Order Form or, if not reflected in the Order
          Form, in effect at the time a fee or charge is due and payable.
          Payments must be made in advance unless otherwise mutually
          agreed upon. All payment obligations are non-cancelable and all
          amounts paid are nonrefundable. Odyssey may require a valid
          credit card as a condition to signing up for the Service.
          Customer hereby authorizes Odyssey to charge its credit card or
          other payment method the fees applicable to Customer’s use of
          the Service. An authorized License Administrator may add
          license rights by executing an additional written Order Form
          and paying the applicable fees. Odyssey reserves the right to
          modify its fees and charges and to introduce new charges at any
          time, upon at least thirty (30) days prior notice to Customer,
          which notice may be provided by email.
        </li>
        <li dir="ltr">
          <b>Billing and Renewal</b>. Odyssey charges and collects in advance
          for use of the Service. Any amounts not paid when due shall
          bear interest at the rate of one and one half percent (1.5%)
          per month, or the maximum legal rate, if less. Odyssey shall be
          entitled to withhold performance and discontinue service until
          all amounts due are paid in full. Odyssey’s fees are exclusive
          of all taxes, levies or duties imposed by taxing authorities,
          and Customer shall be responsible for payment of all such
          taxes, levies, or duties, excluding only United States taxes
          based solely on Odyssey’s income. Customer agrees to provide
          Odyssey with complete and accurate billing and contact
          information. This information includes Customer’s legal company
          name, street address, email address and name and telephone
          number of an authorized billing contact and License
          Administrator. Customer agrees to update this information
          within thirty (30) days of any change to it. If the contact
          information Customer have provided is false or fraudulent,
          Odyssey may terminate Customer’s access to the Service in
          addition to any other legal remedies.
        </li>
      </ol>
    </li>
    <li dir="ltr">
      <b>CUSTOMER DATA AND CONDUCT</b>.
      <ol>
        <li dir="ltr">
          <b>Customer Logos</b>. Customer grants Odyssey a non-exclusive,
          worldwide, royalty-free and fully paid license to display
          Customer’s logos on customer lists that Odyssey may disclose
          publicly.
        </li>
        <li dir="ltr">
          <b>Content and Conduct</b>. Customer represents and warrants that all
          Customer Data (and the use and distribution thereof via the
          Service) shall not (a) infringe any copyright, trademark, or
          patent; (b) misappropriate any trade secret; (c) contain any
          viruses, worms or other malicious computer programming codes
          able to damage the Service or any systems that provide the
          Service; or (d) otherwise violate the rights of a third party.
        </li>
        <li dir="ltr">
          <b>Systems Use and Security</b>. Odyssey reserves the right to
          establish or modify its general rules and limits relating to
          the storage and processing of Customer Data. Odyssey shall
          provide reasonable logical and physical security designed to
          protect the Service and Customer Data. Odyssey generally uses
          the services of a hosting provider to run the Service, and
          Odyssey and Customer will rely on the security policies and
          practices of that provider. The logical and physical security
          provided by Odyssey will include, but not be limited to (a) a
          defined security administration practice and process including
          account and password administration; and (b) ensuring that only
          authorized Odyssey employees and contractors who have a need
          for physical access to provide the Service will have such
          access to the equipment and Customer Data.
        </li>
        <li dir="ltr">
          <b>Content Loss</b>. Customer represents and warrants that Customer
          shall keep and maintain Customer’s own copy of all Customer
          Data that is provided to the Service and Odyssey. Odyssey is
          not obligated to back up any Customer Data that is posted on
          the Service. Odyssey therefore recommends that Customer create
          backup copies of any Customer Data uploaded to or generated by
          the Service at Customer’s sole cost and expense. Customer
          agrees that any use of the Service contrary to or in violation
          of Customer’s representations and warranties in this section
          constitutes improper and unauthorized use of the Service.
          Customer agrees that Odyssey may (but has no obligation to), in
          Odyssey’s sole discretion, remove or modify any Customer Data
          which it deems to violate Customer’s representations and
          warranties. In the event of a loss of Customer Data caused by
          Odyssey, Odyssey will use commercially reasonable efforts to
          recover the Customer Data. If Odyssey performs recovery
          services for a loss of Customer Data not caused by Odyssey,
          Customer agrees to pay Odyssey’s then-current time and
          materials rate to recover the Customer Data. The foregoing
          constitutes Customer’s sole and exclusive remedy for any loss
          of Customer Data.
        </li>
        <li dir="ltr">
          <b>Access to Customer Data On Termination</b>. In the event Customer’s
          access to the Service is terminated (other than by reason of
          Customer’s breach), Odyssey will make available to Customer a
          file of the Customer Data if requested in writing by Customer
          within thirty (30) days after such termination. Customer agrees
          and acknowledges that Odyssey has no obligation to retain the
          Customer Data, and may delete such Customer Data, at any time
          on or after the thirty-first (31st) day following termination.
          Odyssey reserves the right to withhold, remove and/or discard
          Customer Data, without notice, for any breach, including,
          without limitation, Customer’s non-payment. Upon termination
          for cause, Customer’s right to access or use Customer Data
          shall immediately cease, and Odyssey will have no obligation to
          maintain or provide any Customer Data.
        </li>
        <li dir="ltr">
          <b>Odyssey Use of Data</b>. Odyssey collects data about how the
          Service is used by Customer and other customers (“Usage Data”).
          Odyssey will use Usage Data and Customer Data solely for the
          purposes of providing the Service to Customer and for improving
          the Service. Odyssey will not disclose any Usage Data or
          Customer Data (even if the same is anonymized or de-identified)
          to any third party (other than to service providers for the
          purpose of providing a service to Odyssey or Customer), unless
          and until Customer has expressly agreed to such disclosure.
          However, Odyssey may disclose high-level statistics about the
          Service (based on the aggregate of all or most Usage Data,
          Customer Data, and Other Customer Data), such as the number and
          type of customers using the platform, the number projects
          designed in Odyssey and/or funded via the platform, the total
          amount of funding required for projects designed in Odyssey,
          the total number of megawatts of projects designed or tracked
          via the platform, or the total amount of funds facilitated by
          the platform.
        </li>
        <li dir="ltr">
          <b>Other Customer Use of Customer Data</b>. Third parties (including
          other customers) will have access to Customer’s organization
          profile information (which are available for general viewing by
          all customers of the Service). Customer may share other
          Customer Data with other customers of the Service. Customer
          agrees to not use or disclose any customer data of any other
          customer of the Service (“Other Customer Data”) with any third
          party without the express written permission of such other
          customer. Each other customer is an express third party
          beneficiary of this provision of these Terms; but Odyssey will
          have no obligation to anyone to enforce this provision. Nothing
          in these Terms restrict Customer from entering into any
          agreement (e.g., an NDA) with additional or different terms and
          conditions with other customers or third parties.
        </li>
      </ol>
    </li>
    <li dir="ltr">
      <b>CUSTOMER INTERACTION</b>.
      <ol>
        <li dir="ltr">
          <b>Venue</b>. Odyssey does not take part in the interaction between
          customers using the Service. <b>Odyssey has no obligation to
          review, does not verify, and does not have any control over any
          information provided by one customer or user to another and
          expressly disclaims any warranties with respect to such
          information. Neither Odyssey nor its affiliates, licensors, and
          service providers is responsible for the conduct, whether
          online or offline, of any customer or user of the Service</b>.
          Customers may contract directly with other customers. Odyssey
          will not be a party to any investments or contracts between
          customers.
        </li>
        <li dir="ltr">
          <b>Third Party Content</b>. Customer represents and warrants that any
          Content from any third party that Customer uses or has access
          to shall not (a) be copied, altered, or redistributed by
          Customer without the prior written consent of the owner of such
          Content; or (b) be used in any documents, reports,
          presentations or publications by Customer without the prior
          written consent of the owner and/or author of such Content.
        </li>
        <li dir="ltr">
          <b>Release. Odyssey expressly disclaims any liability that may
          arise between customers. In the event that Customer has a
          dispute with one or more customers or users (including with
          respect to any Other Customer Data), Customer releases Odyssey
          (and our officers, directors, agents, investors, service
          providers, subsidiaries, and employees) from any and all
          claims, demands, or damages (actual or consequential) of every
          kind and nature, known and unknown, suspected and unsuspected,
          disclosed and undisclosed, arising out of or in any way
          connected with such disputes. If Customer is a California
          resident, Customer hereby waives California Civil Code Section
          1542, which states, “A general release does not extend to
          claims which the creditor does not know or suspect to exist in
          his favor at the time of executing the release, which, if known
          by him must have materially affected his settlement with the
          debtor.</b>
        </li>
        <li dir="ltr">
          <b>Investigations</b>. Odyssey may, but is not obligated to, monitor
          or review the Site and Content at any time. Without limiting
          the foregoing, Odyssey shall have the right, in its sole
          discretion, to remove Content in the Customer Data for any
          reason (or no reason), including if such Content violates the
          Terms or any applicable law. Although Odyssey does not
          generally monitor customer activity occurring in connection
          with the Site or Content, if Odyssey becomes aware of any
          possible violations by Customer of any provision of the Terms,
          Odyssey reserves the right to investigate such violations, and
          Odyssey may, at its sole discretion, immediately terminate
          Customer’s license to use the Service, or change, alter or
          remove such Content, in whole or in part, without prior notice
          to Customer.
        </li>
        <li dir="ltr">
          <b>Third-Party Services</b>. The Service may contain information about
          and links to third-party websites and resources (<b>“Third-Party
          Sites”</b>) and may include services provided by third parties
          (<b>“Third-Party Services”</b>) as described on the Service. Customer
          uses all such Third-Party Sites and Third-Party Services
          (including all information and links) at its own risk. Odyssey
          does not monitor or have any control over, and makes no claim
          or representation regarding Third-Party Sites and Third-Party
          Services. To the extent links to Third-Party Services are
          provided by Odyssey, they are provided only as a convenience,
          and such links do not imply Odyssey’s endorsement, adoption or
          sponsorship of such Third-Party Site. Odyssey may have a
          referral relationship with one or more Third-Party Site,
          through which Odyssey earns fees in connection with purchases
          Customer makes on the Third-Party Site. Odyssey’s terms and
          policies do not govern any Third-Party Site. Odyssey expressly
          disclaims any representations regarding all third-party sites,
          content, information, products, services and offerings. Odyssey
          is not responsible or liable, directly or indirectly, for any
          damage, loss or liability caused or alleged to be caused by or
          in connection with any use of or reliance on any of the
          foregoing. If Odyssey makes any Third-Party Service available
          through the Service, indicates on the Service that such
          Third-Party Service is subject to additional terms (<b>“Additional
          Terms”</b>) which may be terms between Customer the Third-Party
          Service provider), and Customer uses such Third-Party Service,
          Customer acknowledges that designated Customer Data may be
          transferred to the Third-Party Service provider (for purposes
          of providing the Third-Party Service) and agrees to the
          Additional Terms applicable to such Third-Party Service.
        </li>
      </ol>
    </li>
    <li dir="ltr">
      <b>LIMITED WARRANTY AND DISCLAIMER</b>.
      <ol>
        <li dir="ltr">
          <b>Limited Warranty</b>. Odyssey warrants that it will provide the
          Service in a manner consistent with general industry standards
          reasonably applicable to the provision thereof and that the
          Service will materially conform to Odyssey’s then-current
          documentation for the Service under normal use and
          circumstances. If Customer notifies Odyssey of a breach of
          warranty, Odyssey will re-perform the nonconforming service.
          The foregoing constitutes Customer’s sole and exclusive remedy
          for any breach of warranty.
        </li>
        <li dir="ltr">
          <b>Disclaimer. The limited warranty set forth in these terms is
          made for Customer’s benefit only. Except as expressly provided
          in this section and to the maximum extent permitted by
          applicable law, the Service is, and any Third-Party Services
          are, provided “as is,” and Odyssey makes no (and hereby
          disclaims all) warranties, representations, or conditions,
          whether written, oral, express, implied or statutory,
          including, without limitation, any implied warranties of
          merchantability, title, noninfringement, or fitness for a
          particular purpose, with respect to the use, misuse, or
          inability to use the Service (in whole or in part) or any
          Third-Party Service or other products or services provided to
          Customer by Odyssey or any third party. Odyssey does not
          warrant that all errors can be corrected, or that operation of
          the Service or any Third-Party Service shall be uninterrupted
          or error-free.</b>
        </li>
        <li dir="ltr">
          <b>Internet delays. The Service may be subject to limitations,
          delays, and other problems inherent in the use of the internet
          and electronic communications. Odyssey is not responsible for
          any delays, delivery failures or other damages resulting from
          such problems.</b>
        </li>
      </ol>
    </li>
    <li dir="ltr">
      <b>LIMITATION OF LIABILITY</b>.
      <ol>
        <li dir="ltr">
          <b>Types of Damages. To the extent legally permitted under
          applicable law, in no event shall Odyssey, or its suppliers or
          service providers, be liable to Customer for any special,
          indirect, incidental or consequential damages, including
          damages or costs due to loss of profits, data, use or goodwill,
          personal or property damage resulting from or in connection
          with the Terms or the Service, regardless of the cause of
          action or the theory of liability, whether in tort, contract,
          or otherwise, even if Odyssey has been notified of the
          likelihood of such damages.</b>
        </li>
        <li dir="ltr">
          <b>Amount of Damages. In no event will Odyssey’s liability or
          obligation arising out of or in any way connected to the
          Service or these Terms exceed the fees paid by Customer to
          Odyssey during the twelve (12) months immediately preceding the
          claim. The existence of one or more claims will not increase
          Odyssey’s liability. In no event shall Odyssey’s suppliers or
          service providers have any liability arising out of or in any
          way connected to the Service or these Terms.</b>
        </li>
        <li dir="ltr">
          <b>Basis of the Bargain</b>. The parties agree that the limitations of
          liability set forth in this section shall survive and continue
          in full force and effect despite any failure of consideration
          or of an exclusive remedy. The parties acknowledge that the
          prices have been set and the Terms entered into in reliance
          upon these limitations of liability and that all such
          limitations form an essential basis of the bargain between the
          parties.
        </li>
      </ol>
    </li>
    <li dir="ltr">
      <b>INDEMNIFICATION</b>.
      <ol>
        <li dir="ltr">
          <b>By Odyssey</b>. Odyssey will defend at its expense any suit brought
          against Customer, and will pay any settlement Odyssey makes or
          approves, or any damages finally awarded in such suit, insofar
          as such suit is based on a claim by any third party alleging
          that the Service misappropriates any trade secret recognized
          under the Uniform Trade Secrets Act or infringes any United
          States copyright. If any portion of the Service becomes, or in
          Odyssey’s opinion is likely to become, the subject of a claim
          of infringement, Odyssey may, at Odyssey’s option: (a) procure
          for Customer the right to continue using the Service; (b)
          replace the Service with non-infringing services which do not
          materially impair the functionality of the Service; (c) modify
          the Service so that it becomes non-infringing; or (d) terminate
          the Service and refund any fees actually paid by Customer to
          Odyssey for the remainder of the License Term then in effect,
          and upon such termination, Customer will immediately cease all
          use of the Service. Notwithstanding the foregoing, Odyssey
          shall have no obligation under this section or otherwise with
          respect to any infringement claim based upon (w) any use of the
          Service not in accordance with these Terms or the
          documentation; (x) Odyssey’s conformance to Customer’s
          specifications; (y) any use of the Service in combination with
          other products, equipment, software or Content not supplied by
          Odyssey; or (z) any modification of the Service by any person
          other than Odyssey or its authorized agents. This subsection
          states Customer’s sole and exclusive remedy for infringement
          claims and actions.
        </li>
        <li dir="ltr">
          <b>By Customer</b>. Customer will defend at Customer’s expense any
          suit brought against Odyssey and will pay any settlement
          Customer makes or approves or any damages finally awarded in
          such suit insofar as such suit is based on a claim by any third
          party based upon, resulting from, or related to Customer Data
          or any improper or unauthorized use of the Service by Customer
          or Customer’s Users. This section states the sole and exclusive
          remedy of Odyssey for such claims and actions.
        </li>
        <li dir="ltr">
          <b>Procedure</b>. The indemnifying party’s obligations as set forth
          above are expressly conditioned upon each of the following: (a)
          the indemnified party shall promptly notify the indemnifying
          party in writing of any threatened or actual claim or suit; (b)
          the indemnifying party shall have sole control of the defense
          or settlement of any claim or suit; and (c) the indemnified
          party shall cooperate with the indemnifying party to facilitate
          the settlement or defense of any claim or suit.
        </li>
      </ol>
    </li>
    <li dir="ltr">
      <b>TERMINATION</b>.
      <ol>
        <li dir="ltr">
          <b>Term</b>. These Terms shall commence on the date Customer accepts
          them and shall continue for a period of one (1) year, unless
          terminated earlier in accordance with the terms herein (the
          <b>“Initial Term”</b>). These Terms shall automatically renew for
          successive one (1) year periods (each, a “Renewal Term” and
          collectively with the Initial Term, the <b>“Term”</b>), unless either
          party gives written notice of non-renewal at least thirty (30)
          days prior to the expiration of the then current-term. Either
          party may terminate these Terms upon written notice if the
          other party materially breaches these Terms and does not cure
          such breach (if curable) within thirty (30) days after written
          notice of such breach. Odyssey may also terminate or suspend
          Customer’s right to use the Service if Odyssey believes
          Customer is creating problems or possible legal liabilities, if
          Odyssey believes such action will improve the security of the
          community or reduce another customer’s exposure to financial
          liabilities, or if Odyssey believes Customer is infringing the
          rights of third parties. In addition to terminating or
          suspending Customer’s account, Odyssey reserves the right to
          take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress. Even after
          Customer’s right to use the Service is terminated or suspended,
          these Terms will remain enforceable against Customer.
        </li>
        <li dir="ltr">
          <b>Termination by Customer</b>. Customer may terminate these Terms
          between Customer and Odyssey by (a) notifying Odyssey at any
          time; and (b) closing Customer’s accounts for the Service.
        </li>
        <li dir="ltr">
          <b>Termination by Odyssey</b>. Odyssey may at any time terminate these
          Terms with Customer if (a) Customer has breached any provision
          of these Terms (or have acted in a manner that clearly shows
          Customer does not intend to, or is unable to, comply with these
          Terms); (b) Odyssey is required to do so by law (for example,
          where the provision of the Site or Service to Customer is, or
          becomes, unlawful); (c) the provision of the Service to
          Customer by Odyssey is, in Odyssey’s opinion, no longer
          commercially viable; or (d) Odyssey has elected to discontinue
          the Site or the Service (or any part thereof).
        </li>
        <li dir="ltr">
          <b>Effect of Termination</b>. Termination of these Terms between
          Customer and Odyssey includes: (a) removal of access to all
          offerings within the Service; (b) deletion of Customer’s
          password and all related information; and (c) barring of
          further use of the Service. Upon expiration or termination,
          Customer shall promptly discontinue use of the Site and the
          Service, and Customer’s payment obligations will survive
          (except if Odyssey has terminated the Terms pursuant to
          subparts “(b)”, “(c)”, or “(d)” in the section titled
          Termination by Odyssey). However, the sections titled IP
          Ownership, Payment, Customer Data and Content, Content Loss,
          Third Party Content, Limited Warranty and Disclaimer,
          Limitation of Liability, Indemnification, Termination and
          Miscellaneous of these Terms will survive any termination of
          the Terms.
        </li>
      </ol>
    </li>
    <li dir="ltr">
      <b>MISCELLANEOUS</b>.
      <ol>
        <li dir="ltr">
          <b>Governing Law and Venue</b>. These Terms and any action related
          thereto will be governed and interpreted by and under the laws
          of the State of Colorado (consistent with the Federal
          Arbitration Act), without giving effect to any conflicts of
          laws principles that require the application of the law of a
          different jurisdiction. Customer hereby expressly consents to
          the personal jurisdiction and venue in the state and federal
          courts for the county in which Odyssey’s principal place of
          business is located for any lawsuit filed there against
          Customer by Odyssey arising from or related to these Terms. The
          United Nations Convention on Contracts for the International
          Sale of Goods does not apply to these Terms. Customer shall
          always comply with all international and domestic laws,
          ordinances, regulations, and statutes that are applicable to
          its purchase and use of the Service hereunder.
        </li>
        <li dir="ltr">
          <b>Dispute Resolution</b>. Any dispute arising from or relating to the
          subject matter of this Agreement that cannot be resolved by the
          parties, shall be finally settled by arbitration in Denver,
          Colorado, in accordance with the Arbitration Rules and
          Procedures of Judicial Arbitration and Mediation Services, Inc.
          (“JAMS”) then in effect, by one commercial arbitrator with
          substantial experience in resolving complex commercial contract
          disputes, who shall be selected from the appropriate list of
          JAMS arbitrators in accordance with such Rules. The arbitrator
          shall have the authority to grant specific performance and to
          allocate between the parties the costs of arbitration
          (including service fees, arbitrator fees and all other fees
          related to the arbitration) in such equitable manner as the
          arbitrator may determine. The prevailing party in the
          arbitration shall be entitled to receive reimbursement of its
          reasonable expenses (including reasonable attorneys’ fees,
          expert witness fees and all other expenses) incurred in
          connection therewith. Notwithstanding the foregoing, each party
          shall have the right to institute an action in a court of
          proper jurisdiction for preliminary injunctive or other
          equitable relief without recourse to arbitration.
        </li>
        <li dir="ltr">
          <b>Export</b>. Customer will not to export, reexport, or transfer,
          directly or indirectly, any U.S. technical data acquired from
          Odyssey, or any products utilizing such data, in violation of
          the United States export laws or regulations.
        </li>
        <li dir="ltr">
          <b>Local Laws</b>. Odyssey and its suppliers and service providers
          make no representation that the Service is appropriate or
          available for use in locations other than the United States. If
          Customer use the Service from outside the United States,
          Customer is solely responsible for compliance with all
          applicable laws, including without limitation export and import
          regulations of other countries. Any diversion of the Content
          contrary to United States law is prohibited.
        </li>
        <li dir="ltr">
          <b>Severability</b>. If any provision of these Terms is, for any
          reason, held to be invalid or unenforceable, the other
          provisions of these Terms will remain enforceable and the
          invalid or unenforceable provision will be deemed modified so
          that it is valid and enforceable to the maximum extent
          permitted by law. Without limiting the generality of the
          foregoing, Customer agrees that the section titled Limitation
          of Liability will remain in effect notwithstanding the
          unenforceability of any provision in the section titled Limited
          Warranty and Disclaimer.
        </li>
        <li dir="ltr">
          <b>Waiver</b>. Any waiver or failure to enforce any provision of these
          Terms on one occasion will not be deemed a waiver of any other
          provision or of such provision on any other occasion.
        </li>
        <li dir="ltr">
          <b>Remedies</b>. The parties acknowledge that any actual or threatened
          breach of the section titled License Grant and Restrictions
          will constitute immediate, irreparable harm to the
          non-breaching party for which monetary damages would be an
          inadequate remedy, and that injunctive relief is an appropriate
          remedy for such breach. If any legal action is brought to
          enforce these Terms, the prevailing party will be entitled to
          receive its attorneys’ fees, court costs, and other collection
          expenses, in addition to any other relief it may receive.
        </li>
        <li dir="ltr">
          <b>No Assignment</b>. Neither party shall assign, subcontract,
          delegate, or otherwise transfer these Terms, or its rights and
          obligations herein, without obtaining the prior written consent
          of the other party, and any attempted assignment, subcontract,
          delegation, or transfer in violation of the foregoing will be
          null and void; provided, however, that either party may assign
          these Terms in connection with a merger, acquisition,
          reorganization or sale of all or substantially all of its
          assets, or other operation of law, without any consent of the
          other party. These Terms shall be binding upon the parties and
          their respective successors and permitted assigns.
        </li>
        <li dir="ltr">
          <b>Force Majeure</b>. Any delay in the performance of any duties or
          obligations of either party (except the payment of money owed)
          will not be considered a breach of these Terms if such delay is
          caused by a labor dispute, shortage of materials, fire,
          earthquake, flood, or any other event beyond the control of
          such party, provided that such party uses reasonable efforts,
          under the circumstances, to notify the other party of the cause
          of such delay and to resume performance as soon as possible.
        </li>
        <li dir="ltr">
          <b>Independent Contractors</b>. Customer’s relationship to Odyssey is
          that of an independent contractor, and neither party is an
          agent or partner of the other. Customer will not have, and will
          not represent to any third party that it has, any authority to
          act on behalf of Odyssey.
        </li>
        <li dir="ltr">
          <b>Notices</b>. Customer is responsible for updating Customer’s data
          to provide Odyssey with Customer’s most current email address.
          In the event that the last email address Customer have provided
          to Odyssey is not valid, or for any reason is not capable of
          delivering to Customer any notices required by these Terms,
          Odyssey’s dispatch of the email containing such notice will
          nonetheless constitute effective notice of the changes
          described on the notice. Any notice provided to Odyssey
          pursuant to these Terms should be sent to the Odyssey address
          specified on the Site.
        </li>
        <li dir="ltr">
          <b>Entire Agreement</b>. These Terms are the final, complete and
          exclusive agreement of the parties with respect to the subject
          matters hereof and supersede and merge all prior discussions
          between the parties with respect to such subject matters.
        </li>
        <li dir="ltr">
          <b>Electronic Communications</b>. The communications between Customer
          and Odyssey use electronic means, whether Customer visits the
          Site or send Odyssey e-mails, or whether Odyssey posts notices
          on the Site or communicates with Customer via e-mail. For
          contractual purposes, Customer (1) consents to receive
          communications from Odyssey in an electronic form; and (2)
          agrees that all terms and conditions, agreements, notices,
          disclosures, and other communications that Odyssey provides to
          Customer electronically satisfy any legal requirement that such
          communications would satisfy if it were to be in writing. The
          foregoing does not affect Customer’s statutory rights.
        </li>
      </ol>
    </li>
    <br/>
    <p dir="ltr">
      Last Modified: October 8, 2019
    </p>
    <br/>
    <p dir="ltr">
      <b>Procedure for Making Claims of Copyright Infringement</b>.
    </p>
    <p dir="ltr">
      It is Odyssey’s policy to terminate privileges of any customer who
      repeatedly infringes copyright upon prompt notification to Odyssey by the
      copyright owner or the copyright owner’s legal agent. Without limiting the
      foregoing, if you believe that your work has been copied and posted on
      Odyssey Properties in a way that constitutes copyright infringement, please
      provide Odyssey’s Copyright Agent with the following information: (1) an
      electronic or physical signature of the person authorized to act on behalf
      of the owner of the copyright interest; (2) a description of the
      copyrighted work that you claim has been infringed; (3) a description of
      the location on the Site of the material that you claim is infringing; (4)
      your address, telephone number and e-mail address; (5) a written statement
      by you that you have a good faith belief that the disputed use is not
      authorized by the copyright owner, its agent or the law; and (6) a
      statement by you, made under penalty of perjury, that the above information
      in your notice is accurate and that you are the copyright owner or
      authorized to act on the copyright owner’s behalf. Contact information for
      Odyssey’s Copyright Agent for notice of claims of copyright infringement is
      as follows: <b><i>[Include name or title, and physical address of Copyright
        Agent]</i></b>.
    </p>
    <br/>
  </ol>
</div>
