import { Component, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { EulaType } from './eula-type.enum';

@Component({
  selector: 'oes-terms-of-service-and-privacy-policy',
  templateUrl: './terms-of-service-and-privacy-policy.component.html',
  styleUrls: ['./terms-of-service-and-privacy-policy.component.scss']
})
export class TermsOfServiceAndPrivacyPolicyComponent {
  @ViewChild('termsOfServiceAndPrivacyPolicyDialog', {static: false}) termsOfServiceAndPrivacyPolicyDialog: ModalDirective;

  eulaType: EulaType;

  constructor(public bsModalRef: BsModalRef) {
  }

  getTitle() {
    if (this.eulaType === EulaType.termAndPrivacy) {
      return 'Odyssey Energy Solutions - Subscription Terms of Service & Privacy Policy';
    } else if (this.eulaType === EulaType.conflictInterest) {
      return 'Odyssey Energy Solutions - Conflict of Interest Policy';
    }
  }
}
