import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EulaType } from './eula-type.enum';
import { TermsOfServiceAndPrivacyPolicyComponent } from './terms-of-service-and-privacy-policy.component';

@Injectable({
  providedIn: 'root'
})
export class TermsOfServiceAndPrivacyPolicyService {
  private bsModalRef: BsModalRef;

  constructor(private _modalService: BsModalService) {
  }


  public open(eulaType: EulaType) {
    const config = {
      initialState: {
        eulaType: eulaType
      },
      class: 'modal-lg termsOfServiceAndPrivacyPolicyDialog',
      animated: false,
      keyboard: true,
      ignoreBackdropClick: true,
    };
    this.bsModalRef = this._modalService.show(TermsOfServiceAndPrivacyPolicyComponent, config);
  }
}
