<div
  bsModal
  #termsOfServiceAndPrivacyPolicyDialog="bs-modal">
  <div class="modal-content">
    <div class="modal-header d-flex flex-row justify-content-between">
      <h4 class="modal-title">{{getTitle()}}</h4>
      <button
        type="button"
        class="close"
        (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <oes-terms
        [requestEulaType]="eulaType">
      </oes-terms>
    </div>
  </div>
</div>
