import { Component } from '@angular/core';

@Component({
  selector: 'oes-conflict-of-interest-policies',
  templateUrl: './conflict-of-interest-policies.component.html',
  styleUrls: ['./conflict-of-interest-policies.component.scss']
})
export class ConflictOfInterestPoliciesComponent {

  constructor() { }
}
