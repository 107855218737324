import { Component, Input } from '@angular/core';
import { EulaType } from '../eula-type.enum';

@Component({
  selector: 'oes-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  @Input() requestEulaType: EulaType;

  eulaType = EulaType;

  constructor() {
  }
}
