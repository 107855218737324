<div>
  <h3 class="privacy-policy-heading">Odyssey Energy Solutions - Conflict of Interest Policy</h3>
  <p dir="ltr">
    As a service provider to many different stakeholders in the distributed
    energy sector, Odyssey Energy Solutions, “Odyssey”, adheres to a strict
    conflict of interest policy to protect the confidentiality of its users and
    ensure the impartiality of the platform.
  </p>
  <br />
  <p dir="ltr">
    I. <b>Odyssey provides all users support in using the software across its
    suite of product features</b>. Users may contact the Odyssey support team via
    multiple channels. Odyssey offers technical assistance to all users,
    ensuring that the software is being used properly and that it can be
    continuously improved. Providing excellent user support is one of Odyssey’s
    top priorities.
  </p>
  <br />
  <p dir="ltr">
    II. Odyssey hosts financing programs on the platform, enabling users to
    submit web-based applications to program administrators. As a technology 
    service provider for program administrators, <b>Odyssey does not participate
    in the evaluation of any application submitted to a financing program hosted
    by the platform</b>.
  </p>
  <br />
  <p dir="ltr">
    III. In addition, <b>Odyssey does not assist with preparation of financing
    applications or advise on the
    technical, financial, or other content in any user’s application for any
    financing program on the platform</b>. As noted above, Odyssey offers users
    support in using the software (only) to prepare their proposals.
  </p>
  <ul>
    <li dir="ltr">
      If requested by a financing program administrator, Odyssey may offer all
      program participants
      proposal reviews prior to submission. Proposal reviews ensure the
      application is complete and the software has been used properly. Such
      support is contingent on the applicant submitting a request for a review by
      a specified deadline. Proposal reviews do not evaluate the merits of an
      application nor do they provide any guidance or advice for submission
      content.
    </li>
  </ul>
  <p dir="ltr">
    IV. Odyssey offers its users the option for additional training and/or
    advanced support services. For any work that will be submitted to a
    financing program hosted by the platform, such support is limited to use of
    the software only (assisting with file uploads, etc). <b>No Odyssey employee
    may offer advisory assistance in preparing an application for any financing
    program hosted in the software</b>. Examples of advisory assistance include
    advice on assumptions to enter into the platform, assistance with technical
    designs, or any other advice on an application that is not shared with all
    program participants.
  </p>
  <br />
  <p dir="ltr">
    V. Outside of hosting financing programs, Odyssey offers additional paid
    services via the platform, including
    asset management, mobile site surveying, data analytics, and additional
    assistance with investor or supplier engagement via the platform. All
    information related to any customer engagement is strictly confidential.
    Furthermore, <b>all information or data related to any user of the platform is
    strictly confidential and may never be used to inform client engagements</b>.
    No client that engages Odyssey for such services will receive
    differentiated support from Odyssey during the course of creating an
    application to a financing program.
  </p>

  <!-- Data Policy -->
  <h3 class="mb-3 mt-5">Odyssey Energy Solutions - Data Policy FAQs</h3>
  <p dir="ltr">
    <b>Summary</b>
  </p>
  <ul>
    <li dir="ltr">
      Odyssey is software-as-a-service (SaaS) company that provides software
      solutions and related services to support its users (or “customers”) in
      managing data.
    </li>
    <li dir="ltr">
      Data entered into the platform is shared with other users if – and only
      if – a user chooses to do so by taking explicit actions via the platform or
      if the user requests Odyssey to securely share data with another
      organization. These decisions are governed by a user’s direct relationship
      with the other user.
    </li>
    <li dir="ltr">
      Odyssey does not own any customer data entered into the platform, and
      generally <b>cannot use customers’ data in any way</b> other than to provide
      services to its customers and improve the platform.
    </li>
  </ul>
  <p dir="ltr">
    <b>Who can access customer data entered in the platform?</b>
  </p>
  <br />
  <p dir="ltr">
    Only users with login credentials to a customer’s account can access data
    in the account, unless a user on such customer’s account has explicitly
    selected to share data with another customer. Discrete data modules for
    individual projects can be shared with other customers in the “Sharing”
    section of a customer’s account.
  </p>
  <br />
  <p dir="ltr">
    <b>How does Odyssey handle ownership of customer data?</b>
  </p>
  <br />
  <p dir="ltr">
    Customer data entered into the platform is owned by the customer into whose
    account the data is entered. Odyssey does not own such customer data.
    Odyssey will facilitate data sharing between customers of the platform
    (provided the applicable customer explicitly takes actions to share its
    data), but data ownership between such customers should be arranged
    separately and the customers must abide by such separate agreements when
    using the platform. Odyssey’s Subscription Terms of Service &amp; Privacy
    Policy (“Terms”), which all customers are required to agree to when
    registering with the platform, provide that all customers agree not to use
    or disclose any data of any other customer shared via the platform with any
    third party without the express written permission of such other customer.
  </p>
  <br />
  <p dir="ltr">
    <b>What is Odyssey’s confidentiality and data privacy policy?</b>
  </p>
  <br />
  <p dir="ltr">
    Data privacy is a paramount concern at Odyssey. Generally, Odyssey may not
    use customer data in any way other than to provide services to the customer
    and improve the platform. Odyssey will not disclose any customer data
    entered into the platform to third parties (other than to its service
    providers or as may be required by law) unless the customer has expressly
    agreed to such disclosure. Odyssey may disclose high-level statistics
    regarding usage of the platform itself such as the number and type of
    customers using the platform, the number projects designed in Odyssey
    and/or funded via the platform, the total amount of funding required for
    projects designed in Odyssey, the total number of megawatts of projects
    designed or tracked via the platform, or the total amount of funds
    facilitated by the platform. Customers can view the Terms for a full
    description of our data usage and disclosure practices.
  </p>
  <br />
  <p dir="ltr">
    Odyssey also follows industry standard practices for providing software
    support to customers. Odyssey’s support team may access a customer’s
    account solely for the purposes of providing requested support to the
    customer and improving the platform, as is standard with
    software-as-a-service providers. Odyssey’s support team must adhere to the
    privacy policies described above.
  </p>
</div>